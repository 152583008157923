<template>
  <div
    :class="type"
    class="spinner"
  />
</template>

<script>
export default {
  name: "ItLoaderSpinner",
  props: {
    type: {
      type: String,
      required: false,
      default: 'dark'
    },
  }
}
</script>

<style scoped>
.spinner {
  position: absolute;
  height: 114px;
  width: 114px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  background: rgb(197, 197, 205) conic-gradient(from 90deg at 50% 50%, transparent 280deg, rgb(21, 22, 61) 350deg);
}

.spinner::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 86px;
  width: 86px;
  border-radius: 50%;
  background-color: #EEEEEE;
  color: #EEEEEE;
}

.spinner::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: #15163D;
  color: #15163D;
}

.spinner.dark {
  background: conic-gradient(from 90deg at 50% 50%, transparent 280deg, rgb(65, 65, 102) 350deg);
}

.spinner.dark::before {
  background-color: #374151;
  color: #374151;
}

.spinner.dark::after {
  background-color: #4b5563;
  color: #4b5563;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
