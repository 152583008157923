<template>
  <main
    :class="type"
    class="loader-container"
  >
    <it-loader-spinner :type="type" />
  </main>
</template>

<script>
import ItLoaderSpinner from "./ItLoaderSpinner";
export default {
  name: "ItLoader",
  components: {
    ItLoaderSpinner
  },
  props: {
    type: {
      default: 'light',
      type: String,
      validator: (value) => ['light', 'dark'].includes(value),
    },
  },
  mounted() {
    document.body.classList.add('overflow-hidden');
  },
  beforeDestroy() {
    document.body.classList.remove('overflow-hidden');
  },
};
</script>

<style scoped>
.loader-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 9999;
  background-color: #EEEEEE;
  color: #EEEEEE;
}

.loader-container.dark {
  background-color: #15163D;
  color: #15163D;
}
</style>
