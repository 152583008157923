<template>
  <div>
    <it-loader />
  </div>
</template>

<script>
import { signInWithCustomToken } from 'firebase/auth';
import ItLoader from "../components/ItLoader.vue";

export default {
  components: {ItLoader},
  async mounted() {
    await this.handleAuth();
  },
  methods: {
    async handleAuth() {
      const status = await this.$store.state.AuthFirebase.auth.currentUser?.getIdToken()
      let decodedState = null;
      let params = {};
      let route = '/dashboard';

      try {
        decodedState = window.atob(decodeURIComponent(this.$route.query.state));
        const state = JSON.parse(decodedState)
        const { redir, ...paramsState } = state.query;

        params = paramsState;

        if(redir){
          route = redir
        }
      } catch (error) {

      }

      if (!status && !this.$route.query.code) {
        await this.$router.push({
          name: 'error',
          params: {
            errorCode: 403
          }
        })
        return;
      }

      if (status && !this.$route.query.code) {
        await this.$router.push({
          path: route,
          query: params
        });
        return;
      }

      try {
        await signInWithCustomToken(this.$store.state.AuthFirebase.auth, this.$route.query.code);

        await this.$router.push({
          path: route,
          query: params
        });
      } catch (err) {
        console.log({ err });
        await this.$router.push({name: 'Login'})
      }
    },
  },
};
</script>

